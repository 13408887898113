<template>
    <div class="top-menu-visible top-menu-dark">
        <div>
            <div class="app-title sub-title">
                <span class="app-title-text">Bookme</span>
                <h2 v-if="title">{{title}}</h2>
                <div class="sliding-nav d-flex justify-content-center">
                    <ul class="nav nav-tabs">
                        <li v-for="(tab, key) in tabs" :key="key">
                            <a
                                    class="tab-heads tab-small"
                                    :class="{ active: active === tab}"
                                    @click="switchTab(tab)"
                            >
                                <slot :name="tabHeadSlotName(tab)">
                                    {{tab}}
                                </slot>
                            </a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="app-content-box bg-gray">
            <slot :name="tabPanelSlotName"></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'TabsNotification',
  props: {
    tabs: Array,
    activeTab: String,
    small: Boolean,
    title: String
  },
  data () {
    return {
      active: this.activeTab,
      selectedBooks: this.selectedSlides,
      messageHasMessage: true,
      notificationArr: [null, 0, null]
    }
  },
  computed: {
    my_id () {
      return this.$store.state.my_id
    },
    tabPanelSlotName () {
      return 'tab-panel-' + this.active
    }
  },
  async created () {
    const token = localStorage.getItem('token')
    await this.$https('/has-new-message', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        this.notificationArr[0] = res.data.response.chat.length
        this.notificationArr[2] = res.data.response.rem.length
      })
      .catch(error => {
        console.log('', error)
      })
  },
  methods: {
    tabHeadSlotName (tabName) {
      return 'tab-head-' + tabName
    },
    switchTab (tabName) {
      this.active = tabName
    }
  }
}
</script>
<style lang="scss" scoped>
    .tab-small {
        font-size: 16px;
    }
    .app-content-box {
      min-height: 100vh;
    }
</style>
