<template>
  <div>
    <TabsNotification :activeTab="activeTab" :tabs="tabs" title="Pożyczone">
      <template slot="tab-head-lend">
        komuś
      </template>
      <template slot="tab-panel-lend">
        <LendList :props="books" />
      </template>

      <template slot="tab-head-borrow">
        od kogoś
      </template>
      <template slot="tab-panel-borrow">
        <BorrowList :props="books" />
      </template>
    </TabsNotification>
  </div>
</template>
<script>
import TabsNotification from '../components/TabsNotification'
import LendList from '../components/LendList'
import BorrowList from '../components/BorrowList'
export default {
  name: 'BorrowLendBooks',
  components: {
    TabsNotification,
    LendList,
    BorrowList
  },
  data () {
    return {
      tabs: ['lend', 'borrow'],
      activeTab: 'lend',
      books: [
        {
          id: 0,
          cover: 'profile-book-cover.jpg',
          author: 'Susane Collins',
          date: '23/06/2020',
          who: 'Jan Kowalski',
          title: 'The Hunger Games'
        },
        {
          id: 1,
          cover: 'profile-book-cover.jpg',
          author: 'Susane Collins',
          date: '23/06/2020',
          who: 'Jan Kowalski',
          title: 'The Hunger Games'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    margin-top: 90px;
  }
  .lend-cover {
    width: 140px;
    height: 216px;
  }
  .wrapper-book-lend {
    padding: 0 50px;
  }
</style>
