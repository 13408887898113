<template>
<div class="wrapper">
  <ul class="notification-list">
    <swipe-list
      ref="list"
      class=""
      :items="books"
      item-key="id"
      @swipeout:click="close"
    >
      <template v-slot="{ item }">
        <li class="notification profile-notification">
          <div class="slide-option no-border" >
            <div class="slide-option-slide">
              <div class="slide-option-content pl-4 pr-3">
                <div class="row">
                  <a>
                    <img :src="require('../assets/images/' + item.cover)"  :alt="item.name"  class="lend-cover"/>
                  </a>
                <div class="notification-content col">
                  <div class="notification-content-header">
                    <div class="col info-title">
                      <h3>{{item.title}}</h3>
                    </div>
                    <div class="col info-title mt-3 mb-3">
                      <a >{{item.author}}</a>
                    </div>
                    <div class="col info-title mt-3 mb-3">
                      <a >od kogo: {{item.who}}</a>
                    </div>
                    <div class="col info-title mt-3 mb-3">
                      <a >data zwrotu: {{item.date}}</a>
                    </div>
                  </div>
                  <div class="container">
                    <button type="button" class="btn-black-full w-100">Oddaj książkę</button>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </li>
      </template>
      <template v-slot:right="{ item }">
        <div @click="remove(item)" class="swipeout-action option-red text-center">
                <a href="#">
                  <i class="far fa-trash-alt"></i>
                  <span class="d-block mt-1">
                    Usuń
                  </span>
                </a>
        </div>
      </template>
      <template v-slot:empty>
        <div>
          Brak wiadomości
        </div>
      </template>
    </swipe-list>
  </ul>
</div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions'
export default {
  name: 'BorrowList',
  components: {
    SwipeList
  },
  props: {
    props: Array
  },
  data () {
    return {
      books: this.props
    }
  },
  methods: {
    remove (item) {
      this.books = this.books.filter(i => i !== item)
    },
    close (e) {
      this.$refs.list.closeActions(e.id)
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
      margin-top: 90px;
    }
    .lend-cover {
      width: 140px;
      height: 216px;
    }
    .wrapper-book-lend {
      padding: 0 50px;
    }
</style>
